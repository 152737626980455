//
// layouts.scss
//

.breakageDepositSwitch {
    display: inline-block;
    margin-left: 10px;
}

.breakageDepositTable tr td {
    vertical-align: middle;
}

.form-control {
    min-height: 40px;
}
input.DatePicker__input.-ltr {
    min-height: 40px;
}

.form-control:disabled,
.form-control[readonly],
input.DatePicker__input:disabled,
.form-select:disabled {
    border: 1px solid var(--ct-input-border-color);
    color: var(--ct-table-color);
}

.css-yk16xz-control {
    border-color: transparent !important;
}
.mb-3 .css-yk16xz-control,
.mb-4 .css-yk16xz-control  {
    border-color: hsl(0, 0%, 80%) !important;
}
.dashboard-filter .css-yk16xz-control {
    border-color: transparent !important;
}
.dashboard-filter-components input.DatePicker__input.-ltr,
.col-sm-6 input.DatePicker__input.-ltr,
.mb-3 input.DatePicker__input.-ltr {
    border-color: transparent !important;
    height: 40px;
}
.bk-calendar .form-control {
    border: solid 1px #ffffff !important;
    height: 40px;
    margin: 0;
}
.bk-calendar .react-select {
    border-radius: 0.2rem;
}
.bk-calendar .react-select__control--is-focused {
    border-color: #ffffff !important;
}
.property-select .react-select__control {
    border-color: transparent !important;
}
.mb-3 .react-select__control,
.mb-4 .react-select__control {
    border-color: #ffffff !important;
    height: 40px;
}
.mb-3 .col-sm-6 .react-select__control {
    border-color: #cccccc !important;
    height: auto;
    height: 40px;
}
.mb-3 .col-sm-6 input.DatePicker__input.-ltr {
    border-color: #cccccc !important;
}
.mb-4 .col-sm-6 input.DatePicker__input.-ltr {
    border-color: #cccccc !important;
}
.mb-3 input.DatePicker__input.-ltr {
    border-color: #cccccc !important;
}
.dashboard-filter-components input.DatePicker__input.-ltr {
    border-color: #ffffff !important;
}
.col-sm-1 .form-select,
.col-sm-2 .form-select {
    height: 40px;
    border-color: #ffffff !important;
}
.me-2 .react-select__control {
    height: 40px;
    border: solid 1px #ffffff;
}
.me-2 .custom-form-select .react-select__control {
    height: 40px;
    border: none;
}
.form-select {
    background-size: 14px 10px;
    height: 40px;
}
.tab-pane .form-select {
    height: 38px;
}
.all-booking-filters {
    height: 40px;
    border: solid 1px #ffffff;
}
.btn {
    height: 40px;
}
.offcanvas-body .react-select__control {
    border-color: #cccccc !important;
    height: auto;
}
.quotation .btn {
    height: auto;;
}

/* --------------------  Dashboard  ------------------  */
.dashboard-start-year {
    margin-left: 0 !important;
}

.donut-chart .apexcharts-datalabels .apexcharts-pie-label,
.donut-chart .apexcharts-datalabels.legend-mouseover-inactive {
    fill: #ffffff !important;
    opacity: 1;
}

/* --------------------  Calendar Paid & Unpaid Labels  ------------------  */
.label-paid {
    background-color:  #1dbd9d;
    display: inline-block;
    padding: 0 5px;
    border-radius: 0.25rem;
    color: #ffffff;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 5px;
}
.label-unpaid {
    background-color:  #ef3b5f;
    display: inline-block;
    padding: 0 5px;
    border-radius: 0.25rem;
    color: #ffffff;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 5px;
}
.label-paid-modal {
    margin-left: 10px;
}
.label-paid-tooltip {
    margin-left: 10px;
    margin-bottom: 0;
    width: auto !important;
}

/* --------------------  Calendar ToolTip  ------------------  */
.calendar-tooltip .calendar-tooltip .value:nth-child(2) span {
    transform: skewX(0deg) !important;
}
.calendar-tooltip .calendar-tooltip .value:nth-child(2) div div {
    display: none !important;
}
.calendar-tooltip-heading {
    white-space: normal !important;
    width: 100% !important;

}

.booking-table .input-group-text {
    min-height: calc(1.5em + 0.56rem + 2px);
    padding: 0.28rem 0.8rem;
    font-size: 0.7875rem;
}

.btn-xero-invoice {
    color: #ef3b60;
}
.btn-xero-invoice:hover {
    color: #ef3b60;
}

.create-booking-modal .btn-close,
.modal-with-close-btn .btn-close {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDEzLjc1IDEzLjc1Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjMmMyNTYxOwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMjguNy4xLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogMS4yLjAgQnVpbGQgMTQyKSAgLS0+CiAgPGc+CiAgICA8ZyBpZD0iTGF5ZXJfMSI+CiAgICAgIDxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIxMy43MSAyIDExLjc1IC4wNCA2Ljg4IDQuOTEgMiAuMDQgLjA0IDIgNC45MSA2Ljg4IC4wNCAxMS43NSAyIDEzLjcxIDYuODggOC44NCAxMS43NSAxMy43MSAxMy43MSAxMS43NSA4Ljg0IDYuODggMTMuNzEgMiIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+') !important;
    background-size: 13.75px !important;
    background-repeat:  no-repeat !important;
    padding: 0;
    margin: 0;
}

.create-booking-modal .form-btn-white,
.create-booking-modal .form-btn-white:hover,
.create-booking-modal .custom-form-select {
    border:  none !important;
}
.create-booking-modal .react-select__control {
    border-color: hsl(210deg 13.95% 83.14%) !important;
}
.create-booking-modal .react-select__single-value {
    color: #767e86 !important;
}
.create-booking-modal input.DatePicker__input.-ltr {
    color: #767e86 !important;
    border-color: hsl(210deg 13.95% 83.14%) !important;
}
.create-booking-modal .react-select__dropdown-indicator svg {
    width: 16px !important;
    height: 16px !important;
    margin-right: 5px !important;
}

.booking-modal .react-select__dropdown-indicator svg {
    width: 16px !important;
    height: 16px !important;
    margin-right: 5px !important;
}


.border-input input.DatePicker__input.-ltr {
    border: 1px solid #ced4da !important;
    border-color: #ced4da !important;
}
.custom-form-select .react-select__control {
    border-color: #ced4da !important;
}
.manage-item-categories div {
    border-color: transparent !important;
}

.property-select .react-select__control {
    border-color: #ffffff !important;
}

/* --------------------  Calendar > Billing  ------------------  */
.calendar-billing-vcard-processed .form-switch {
    width: 25%;
    display: inline-block;
}

/* --------------------  Calendar > Tasks > Welcome Packs  ------------------  */
.modal-body .tab-content .calendar-welcome-packs-hidden {
    display: none !important;
}

/* --------------------  User Edit  ------------------  */
.user-edit-container {
    background-color: #ffffff;
    border-radius: 3px;
    padding: 20px;
    padding-bottom: 1px;
}

/* --------------------  Form Validation Styles  ------------------  */
.was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: none;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%238b96a0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* --------------------  Owner Statement Styles  ------------------  */
.owners-statement-col-new-right-padding {
    padding-right: 0 !important;
    padding-bottom: 10px !important;
}
.owners-statement-checkbox {
    padding-top: 20px;
}
.owners-statement-col-left {
    padding-right: 2.6rem;
}
.owners-statement-col-right {
    padding-left: 1.9rem;
    padding-right: 1.5rem;
}
.owners-statement-col-new-right-padding .form-check-input[disabled] ~ .form-check-label,
.owners-statement-col-new-right-padding .form-check-input:disabled ~ .form-check-label {
    opacity: 1;
}
.react-select .react-select__control--is-disabled {
    border: 1px solid #ced4da !important;
    color: #6c757d;
    background-color: #eeeeee !important;
}
.readonly-input-group {
    border: 1px solid #ced4da;
    color: #6c757d;
    background-color: #eeeeee;
}

/* --------------------  Owner Statement Displays Styles  ------------------  */
.owner-statements-wrapper {
    margin-bottom: 25px;
}
.text-left {
    text-align: left;
}
.owner-statements-block-inner {
    align-items: center;
}
.owner-statements-block-inner .col-sm-6:last-child {
    text-align: right;
}
.owner-statements-block-inner p {
    margin: 0;
    line-height: 18px;
}
/*
.owner-statements-wrapper:last-child {
    margin-bottom: 0;
}
*/
.owner-statements-wrapper-inner {
    background-color: #ffffff;
    border-radius: 3px;
    padding: 25px;
    padding-bottom: 0;
}
.owner-statements-wrapper-inner .col-sm-3 {
    display: flex;
}
.owner-statements-wrapper-inner h4 {
    margin-bottom: 30px;
}
.owner-statements-block {
    background-color: #f4f5f7;
    border-radius: 3px;
    padding: 25px;
    width: 100%;
    margin-bottom: 25px;
}
.owner-statements-block strong {
    font-size: 16px;
    font-weight: 600;
}
.btn-owners-statement-download {
    background-color: #2c2561;
    color: #ffffff;
    display: inline-block;
    border-radius: 3px;
    padding: 10px;
    max-width: 105px;
    width: 100%;
    text-align: center;
}
.btn-owners-statement-download:hover {
    background-color: #251f52;
    color: #ffffff;
}

/* --------------------  Owner Statements For Admin Styles  ------------------  */
.custom-table tr,
.custom-table td {
    z-index: auto;
}
.custom-table .DatePicker {
    z-index: auto;
}
.custom-table .DatePicker__calendarContainer {
    z-index: 9999;
}
.owner-statements-for-admin-pagination {
}
.owner-statements-for-admin-pagination.pagination-previous:disabled,
.owner-statements-for-admin-paginationb .pagination-next:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.owner-statements-for-admin-pagination .paginate_button.next,
.owner-statements-for-admin-pagination .paginate_button.previous {
    padding: 6px 10px; /* Ensure proper clickable area */
    display: inline-flex; /* Ensure it's not hidden */
    border-radius: 30px !important;
    margin: 0 6px;
    border: none;
    color: #98a6ad;
    pointer-events: none;
    background-color: #fff;
    border-color: #dee2e6;
    pointer-events: auto; /* Ensure the button is clickable */
    z-index: 1;           /* Ensure it's not hidden behind another element */
    min-height: 33.5px;
}
.btn-send {
    height: auto !important;
}
.btn-notes {
    position: relative;
    height: auto !important;
}/*
.owner-statements-note-indicated {
    background-color: #ef3b5f;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    color: #ffffff;
    font-size: 11px;
    text-align: center;
    line-height: 16px;
    position: absolute;
    top: -5px;
    right: -5px;
    border: solid 1px #ffffff;
}
.owner-statements-note-indicated-yes {
    background-color: #00d4b6;
}
*/
.owner-statements-note-indicated {
    background-color: #ef3b5f;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    color: #ffffff;
    position: absolute;
    top: 2px;
    right: 2px;
}
.owner-statements-note-indicated-yes {
    background-color: #00d4b6;
}
.owner-statements-table {
    // min-height: 550px;
}
.owner-statements-table .DatePicker .form-control {
    width: 100px;
    padding: 0.45rem;
}
.owner-statements-table th,
.owner-statements-table td {
    white-space: normal !important;
}
.owner-statements-table th:nth-child(7),
.owner-statements-table td:nth-child(7) {
    width: 120px;
}
.owner-statements-table th:nth-child(4),
.owner-statements-table td:nth-child(4),
.owner-statements-table th:nth-child(5),
.owner-statements-table td:nth-child(6),
.owner-statements-table th:nth-child(6),
.owner-statements-table td:nth-child(6),
.owner-statements-table th:nth-child(11),
.owner-statements-table td:nth-child(11),
.owner-statements-table th:nth-child(13),
.owner-statements-table td:nth-child(13),
.owner-statements-table th:nth-child(14),
.owner-statements-table td:nth-child(14) {
    width: 110px;
}
.owner-statements-table th.sortable:before {
    top: 30%;
}
.owner-statements-table th.sortable:after {
    top: 30%;
}
.owner-statements-select .react-select__single-value {
    color: #808080 !important;
}

/* --------------------  Welcome Packs Styles  ------------------  */
.welcome-packs-table-responsive {
    /*
    min-height: 350px;
    padding-bottom: 130px;
    */
}
.welcome-packs-custom-table.react-table th, .welcome-packs-custom-table.react-table td {
    white-space: normal;
}
.welcome-packs-custom-table.react-table th:nth-child(1), .welcome-packs-custom-table.react-table td:nth-child(1),
.welcome-packs-custom-table.react-table th:nth-child(2), .welcome-packs-custom-table.react-table td:nth-child(2) {
    min-width: 120px;
}
.welcome-pack-packed-status-select {
    min-width: 150px;
}
.welcome-pack-packed-status-select .react-select__control {
    border: 1px solid #ced4da !important;
}
.welcome-pack-packed-status-select .react-select__single-value {
    color: #808080 !important;
}
.btn-pack-status {
    background-color: transparent;
    color: #808080;
    border: 1px solid #ced4da;
    width: 100px;
    text-align: left;
    box-shadow: none;
}
.btn-pack-status:hover,
.btn-pack-status:active,
.btn-pack-status:focus {
    background-color: transparent;
    color: #808080;
    border: 1px solid #ced4da;
    box-shadow: none;
}


/* --------------------  Reported Issues Styles  ------------------  */
.reported-issue-heading {
    margin-bottom: 40px;
    text-align: center;
}

.all-bookings-table th,
.all-bookings-table td {
    width: 6%;
}

/* --------------------  Booking - History Tab Styles  ------------------  */
.wati-history-heading {
    font-size:  20px;   
}
.wati-history-list {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}
.wati-history-list li:first-child {
    padding-top: 0;
}
.wati-history-list li {
    border-bottom: solid 1px #dee2e6;
    padding: 1.25rem 0.85rem;
}
.wati-history-list li:last-child {
    padding-bottom: 0;
    border: none;
}
.wati-history-list li div {
    margin-bottom: 1.25rem;
}
.wati-history-list li div:last-child {
    margin-bottom: 0;
}

.wati-heading {
    margin-top: 30px;
}
.custom-accordion h2 {
    margin: 0;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-family: "Nunito", sans-serif;
}
.custom-accordion h2 button {
    padding: 1rem 0.85rem;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-family: "Nunito", sans-serif;
}
.custom-accordion h2 button:focus {
    box-shadow:  none;
}
.custom-accordion .accordion-body p {
    margin-bottom: 1.25rem;
    border-bottom: solid 1px #00000020;
    padding-bottom:  1.25rem;
}
.custom-accordion .accordion-body p:last-child {
    margin-bottom: 1.25rem;
    border-bottom: none;
}

.custom-accordion .accordion-item:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.custom-accordion .accordion-item:last-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.custom-accordion .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.custom-accordion .accordion-button::after {
    width: 16px;
    height: 16px;
    background-image: url("../../../images/accordion-dropdown-icon.svg");
    background-repeat: no-repeat;
    background-size: 16px;
    color: #98a6ad !important;
}
.accordion-item {
    border-top: none;
    border-right: none;
    border-left: none;
}
.accordion-body {
    padding: 1rem 0.85rem;
}
.custom-accordion .accordion-button:not(.collapsed) {
    box-shadow: none;
}

.custom-accordion .accordion-body p:last-child {
    margin-bottom: 0;
}


/* --------------------  All Bookings Styles  ------------------  */
.table.all-bookings-table > :not(caption) > * > * {
    padding: 0.85rem 0.55rem;
}
.all-bookings-table th {
    padding-right: 30px !important;
}
.all-bookings-table th.sortable:before {
    top: 34%;
}
.all-bookings-table th.sortable:after {
    top: 34%;
}
.all-bookings-table th,
.all-bookings-table td {
    white-space: normal !important;
}
.all-bookings-table th:nth-child(1),
.all-bookings-table th:nth-child(2),
.all-bookings-table th:nth-child(9),
.all-bookings-table td:nth-child(1),
.all-bookings-table td:nth-child(2),
.all-bookings-table td:nth-child(9) {
    white-space: nowrap !important;
}
.all-bookings-table th:nth-child(18),
.all-bookings-table td:nth-child(18) {
    display: none;
}

/* --------------------  Create Booking Styles  ------------------  */
.create-booking-property-select .react-select__control {
    border-color: #ccc !important;
}

/* --------------------  Linen Styles  ------------------  */
.linen-invoice-wrapper input.DatePicker__input.-ltr {
    border-color: #ccc !important;
}

/* --------------------  Approved Suppliers Styles  ------------------  */
.edit-suppliers-modal .react-select__control {
    border-color: #ccc !important;
}

/* --------------------  Reported Issues > Allocate To Styles  ------------------  */
.reported-issue-allocate-to .react-select__control {
    border-color: #ccc !important;
}

/* --------------------  Body Styles  ------------------  */
body[data-layout-width="boxed"] {
    #wrapper {
        max-width: $boxed-layout-width;
        margin: 0 auto;
        box-shadow: $box-shadow-lg;
    }

    .navbar-custom {
        max-width: $boxed-layout-width;
        margin: 0 auto;
    }

    .footer {
        margin: 0 auto;
        max-width: calc(#{$boxed-layout-width} - #{$leftbar-width});
    }

    &[data-leftbar-size="condensed"] {
        .footer {
            max-width: calc(#{$boxed-layout-width} - #{$leftbar-width-condensed});
        }
    }

    &[data-leftbar-size="compact"] {
        .footer {
            max-width: calc(#{$boxed-layout-width} - #{$leftbar-width-sm});
        }
    }
}

@media (max-width: 1180px) {

    .owner-statements-wrapper-inner .col-sm-3 {
        flex: 0 0 auto;
        width: 33.333%;
    }

}

@media (min-width: 992px) {

    .modal-lg, .modal-xl {
        max-width: 1000px;
    }

}

@media (max-width: 736px) {

    .owner-statements-wrapper-inner .col-sm-3 {
        flex: 0 0 auto;
        width: 50%;
    }

}

@media (max-width: 568px) {

    .row {
        --ct-gutter-x: 0;
    }
    .owner-statements-wrapper-inner .col-sm-3 {
        flex: 0 0 auto;
        width: 100%;
    }
    .owner-statements-wrapper-inner .col-sm-3 .col-sm-6 {
        display: inline-block;
        width: 50%;
    }

}

// Body min-height set
@include media-breakpoint-up(md) {
    body[data-layout-width="boxed"][data-leftbar-size="condensed"] {
        .content-page {
            min-height: calc(#{$body-min-height} - #{$leftbar-width-condensed});
        }
    }
}

// Scrollable Layout
@include media-breakpoint-up(xl) {
    body[data-menu-position="scrollable"]:not([data-leftbar-size="condensed"]):not([data-leftbar-size="compact"]):not([data-layout-mode="two-column"]) {
        padding-bottom: 0;

        #wrapper {
            display: flex;
        }

        .navbar-custom,
        .topnav {
            position: absolute;
        }

        .left-side-menu {
            position: relative;
            min-width: $leftbar-width;
            max-width: $leftbar-width;
            padding: 20px 0 calc(#{$topbar-height} + 20px);
        }

        .content-page {
            margin-left: 0;
            width: 100%;
            padding-bottom: 60px;
        }
    }
}

